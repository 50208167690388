import api from '@/common/api';
import error from '@/common/nodejs/errors';
import alert from '../alert';

let frontType = 'manage';
export const init = (types) => {
  frontType = types;
};

// 疑似のメンテナンス状態を設定する(コメントアウト)
// let maintenanceMode = false;
// export const setFakeMaintenanceMode = (types) => {
//   maintenanceMode = types;
// };

/* **
 * バックエンドから取得した Response の値をチェックする
 * @param types: this を指定
 * @param response: バックエンドから取得した応答情報
 * @param modal: this を指定
 * @param title: ダイアログのタイトルを指定
 * @param successCallback: 応答が成功した再の関数 ※省略可能
 * @param failureCallback: 応答が失敗した再の関数 ※省略可能
 * @param successMessageId: OKボタンタイトル名を指定 ※省略可能
 * @param isAlert: アラートを自動で表示するかの有無(true:自動表示 false:表示しない) ※省略可能
 */
export const validate = (types, response, modal, title, successCallback = null, failureCallback = null, successMessageId = null, isAlert = true) => {

  let result = false;

  const runValidate = checkResponse(response);
  if (!runValidate) {
    // Backendから応答がない場合は予期せぬエラーを表示する。
    result = false;
    // 値が取得できない場合はバックエンドのエラーをそのまま表示する。
    const value = response && response.status ? response.status : 'no response';
    const message = checkErrorMessage(modal, "", 'status: ' + value);
    if (isAlert) {
      alert.showError(modal, title, message,
        () => {
          if (failureCallback) {
            failureCallback(createValidateResult(behaviorTypes.logout, causeTypes.unexpectedError, message, response));
          }
        });
    }
    else {
      if (failureCallback) {
        failureCallback(createValidateResult(behaviorTypes.logout, causeTypes.unexpectedErrore, message, response));
      }
    }
  }
  else {
    // response の情報に data object が存在するか確認する。 
    switch (types) {
      case validateTypes.all:
        result = isAll(response.data, modal, title, isAlert, successCallback, failureCallback, successMessageId);
        break;
      case validateTypes.permission:
        result = isPermission(response.data, modal, title, isAlert, successCallback, failureCallback, successMessageId);
        break;
      case validateTypes.response:
        result = isResponse(response.data, modal, title, isAlert, successCallback, failureCallback, successMessageId);
        break;
      default:
        // 設定されていない場合は all チェックを実施する。 
        result = isAll(response.data, modal, title, isAlert, successCallback, failureCallback, successMessageId);
        break;
    }
  }
  return result;
};

/* **
 * バックエンドから取得した Response の値が正常かチェックする
 */
function checkResponse(response) {
  if (!response || !response.data) {
    return false;
  }

  // サーバーからの応答が無いエラーになった場合チェック処理を無効に設定 
  if (response.data.code === error.FRONT_SERVER_NO_RESPONCE) {
    return false;
  }

  // code が設定されているかチェックする (※成功の場合もcodeが設定されていない)
  if (!response.data.code) {
    // HttpStatusコードがエラーになっているかチェック
    if (api.isServerSideErrorCode(response.status)) {
      // エラーcode無しで HttpStatusコードがエラーになっている場合はバリデートを実行しない。   
      return false;
    }
  }
  return true;
}

/* eslint-disable max-statements */
function isAll(response, modal, title, isAlert, successCallback, failureCallback, successMessageId,) {
  if (!api.isSuccessResponse(response)) {
    const isAlreadyDeleted = api.isUserAlreadyDeleted(response);
    const isPermissionError = api.isPermissionError(response);
    const isInvalidSessionToken = api.isInvalidSessionToken(response);
    const isServerSideError = api.isServerSideError(response);
    const isClientConnectorNotAliveError = api.isClientConnectorNotAliveError(response);
    const runMaintenance = api.isRunMaintenance(response);
    const isNetwrokDisconnect = api.isNetworkDisconnectError(response);

    let message = '';
    let type = null;
    let causeType = null;

    // ネットワークが切断しているかチェック
    if (isNetwrokDisconnect) {
      const info = getNetworkErrorMessage(modal);
      message = info.message;
      type = info.type;
      causeType = info.causeType;
    }
    // メンテナンス中の場合はメンテナンス中状態を設定
    else if (runMaintenance) {
      message = getMessage(error.SYSTEM_UNDER_MAINTENANCE, modal, 'code');
      type = behaviorTypes.logout;
      causeType = causeTypes.maintenanceError;
    }
    // ユーザー削除エラーと権限チェック
    else if (isAlreadyDeleted || isPermissionError) {
      causeType = causeTypes.alreadyDeleted;
      let messageId = error.USER_ALREADY_DELETED;
      // 権限エラーの場合は権限エラーメッセージIDを設定する。
      if (isPermissionError) {
        messageId = error.USER_ACCESS_PERMISSION_ERROR;
        causeType = causeTypes.permissionError;
      }
      message = getMessage(messageId, modal, 'code');
      type = behaviorTypes.logout;
    }
    // セッションの有効チェック
    else if (isInvalidSessionToken) {
      if (frontType === 'manage') {
        message = getMessage(error.INVALID_SESSION_TOKEN, modal, 'code');
      }
      else {
        message = getMessage('message.common_session_reset_message', modal, 'id');
      }
      type = behaviorTypes.logout;
      causeType = causeTypes.invalidSessionToken;
    }
    // クラウドコネクターの生存確認チェック 
    else if (isClientConnectorNotAliveError) {
      message = getMessage(response, modal);
      type = behaviorTypes.logout;
      causeType = causeTypes.clientConnectorNotAliveError;
    }
    // メンテナンス中待機中のエラー
    else if (error.REPRO_CANT_START_SYSTEM_UNDER_MAINTENANCE === response.code) {
      message = getMessage(response.code, modal, 'code');
      type = behaviorTypes.other;
      causeType = causeTypes.beforMaintenanceError;
    }
    // サーバーサイドのエラーチェック(error code : 400～500)
    // サーバーサイドのエラーは一番優先度を低くチェックする 
    else if (isServerSideError) {
      message = getMessage('message.common_systemError', modal, 'id');
      type = behaviorTypes.logout;
      causeType = causeTypes.unexpectedError;
    }
    else {
      message = getMessage(response, modal);
      type = behaviorTypes.other;
      causeType = causeTypes.other;
    }
    // メッセージが取得できない場合はバックエンドのエラーをそのまま表示する。
    message = checkErrorMessage(modal, message, response.message);

    if (isAlert) {
      alert.showError(modal, title, message,
        () => {
          if (failureCallback) {
            failureCallback(createValidateResult(type, causeType, message, response.code));
          }
        });
    }
    else {
      if (failureCallback) {
        failureCallback(createValidateResult(type, causeType, message, response.code));
      }
    }
    return false;
  }

  if (successMessageId) {
    alert.showSuccess(modal, title, getMessage(successMessageId, modal, 'id'), successCallback);
  }
  else {
    if (successCallback) {
      successCallback();
    }
  }
  return true;
}

function isPermission(response, modal, title, isAlert, successCallback, failureCallback, successMessageId,) {

  if (!api.isSuccessResponse(response)) {
    let message = '';
    let type = null;
    let causeType = null;

    // メンテナンス中かチェック
    const isServerSideError = api.isServerSideError(response);
    const isNetwrokDisconnect = api.isNetworkDisconnectError(response);
    const runMaintenance = api.isRunMaintenance(response);

    message = getMessage(response, modal);
    // ネットワークが切断しているかチェック
    if (isNetwrokDisconnect) {
      const info = getNetworkErrorMessage(modal);
      message = info.message;
      type = info.type;
      causeType = info.causeType;
    }
    // メンテナンス中状態チェック 
    else if (runMaintenance) {
      message = getMessage(error.SYSTEM_UNDER_MAINTENANCE, modal, 'code');
      type = behaviorTypes.logout;
      causeType = causeTypes.maintenanceError;
    }
    else if (api.isPermissionError(response)) {
      message = getMessage(error.USER_ACCESS_PERMISSION_ERROR, modal, 'code');
      type = behaviorTypes.logout;
      causeType = causeTypes.permissionError;
    }
    // サーバーサイドのエラーチェック(error code : 400～500)
    // サーバーサイドのエラーは一番優先度を低くチェックする 
    else if (isServerSideError) {
      message = getMessage('message.common_systemError', modal, 'id');
      type = behaviorTypes.logout;
      causeType = causeTypes.unexpectedError;
    }
    else {
      type = behaviorTypes.other;
      causeType = causeTypes.other;
    }

    // メッセージが取得できない場合はバックエンドのエラーをそのまま表示する。
    message = checkErrorMessage(modal, message, response.message);

    if (isAlert) {
      alert.showError(modal, title, message,
        () => {
          if (failureCallback) {
            failureCallback(createValidateResult(type, causeType, message, response.code));
          }
        });
    }
    else {
      if (failureCallback) {
        failureCallback(createValidateResult(type, causeType, message, response.code));
      }
    }
    return false;
  }

  if (successMessageId) {
    alert.showSuccess(modal, title, getMessage(successMessageId, modal, 'id'), successCallback);
  }
  else {
    if (successCallback) {
      successCallback();
    }
  }
  return true;
}

function isResponse(response, modal, title, isAlert, successCallback, failureCallback, successMessageId) {

  if (!api.isSuccessResponse(response)) {
    let message = '';
    let type = behaviorTypes.other;
    let causeType = causeTypes.other;

    // メンテナンス中かチェック
    const isServerSideError = api.isServerSideError(response);
    const isNetwrokDisconnect = api.isNetworkDisconnectError(response);
    const runMaintenance = api.isRunMaintenance(response);

    message = getMessage(response, modal);
    // ネットワークが切断しているかチェック
    if (isNetwrokDisconnect) {
      const info = getNetworkErrorMessage(modal);
      message = info.message;
      type = info.type;
      causeType = info.causeType;
    }
    // メンテナンス中の場合はメンテナンス中状態を設定
    else if (runMaintenance) {
      message = getMessage(error.SYSTEM_UNDER_MAINTENANCE, modal, 'code');
      causeType = causeTypes.maintenanceError;
      type = behaviorTypes.logout;
    }
    // サーバーサイドのエラーチェック(error code : 400～500)
    // サーバーサイドのエラーは一番優先度を低くチェックする 
    else if (isServerSideError) {
      message = getMessage('message.common_systemError', modal, 'id');
      type = behaviorTypes.logout;
      causeType = causeTypes.unexpectedError;
    }

    // メッセージが取得できない場合はバックエンドのエラーをそのまま表示する。
    message = checkErrorMessage(modal, message, response.message);

    if (isAlert) {
      alert.showError(modal, title, message,
        () => {
          if (failureCallback) {
            failureCallback(createValidateResult(type, causeType, message, response.code));
          }
        });
    }
    else {
      if (failureCallback) {
        failureCallback(createValidateResult(type, causeType, message, response.code));
      }
    }
    return false;
  }

  if (successMessageId) {
    alert.showSuccess(modal, title, getMessage(successMessageId, modal, 'id'), successCallback);
  }
  else {
    if (successCallback) {
      successCallback();
    }
  }
  return true;
}

function createValidateResult(behaviorType, causeType, message = '', code = null) {
  const result = {
    behaviorType: behaviorType,
    causeType: causeType,
    message: message,
    consoleCode: code
  };
  return result;
}

function getMessage(data, modal, type = null) {
  let message = '';
  if (modal) {
    if (typeof data === 'object') {
      message = getErrorMessage(data, modal);
    }
    else if (typeof data === 'string' || typeof data === 'number') {
      if (type === 'code') {
        message = modal.$t(api.getErrorMessageForCode(data));
      }
      else {
        // message ID の直接指定 
        message = modal.$t(data);
      }
    }
    else {
      // 種別が object でも string or number でもない場合は予期せぬエラーを表示する。 
      message = modal.$t('message.common_systemError');
    }
  }
  return message;
}

function getErrorMessage(response, modal) {
  let code = error.INTERNAL_SERVER_ERROR;
  let details = '';
  if (response) {
    code = response.code;
    details = response.details ? response.details : '';
  }
  if (details) {
    let messageKey = 'error.internal_server_error';
    switch (code) {
      case error.DB_USER_TO_APPROVE_NOT_FOUND:
        messageKey = 'error.DB_USER_TO_APPROVE_NOT_FOUND';
        break;
      case error.PFC_FILE_TREE_MORE_THAN_ONCE_REPRO_PUBLISH:
        messageKey = 'error.PFC_FILE_TREE_MORE_THAN_ONCE_REPRO_PUBLISH';
        break;
      case error.PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_PUBLISH:
        messageKey = 'error.PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_PUBLISH';
        break;
      case error.PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_PUBLISH:
        messageKey = 'error.PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_PUBLISH';
        break;
      case error.PFC_FILE_NEW_ID_REPEATED_PUBLISH:
        messageKey = 'error.PFC_FILE_NEW_ID_REPEATED_PUBLISH';
        break;
      case error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_PUBLISH:
        messageKey = 'error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_PUBLISH';
        break;
      case error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_PUBLISH:
        messageKey = 'error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_PUBLISH';
        break;
      case error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_PUBLISH:
        messageKey = 'error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_PUBLISH';
        break;
      case error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_PUBLISH:
        messageKey = 'error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_PUBLISH';
        break;
      case error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_PUBLISH:
        messageKey = 'error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_PUBLISH';
        break;
      case error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_PUBLISH:
        messageKey = 'error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_PUBLISH';
        break;
      default:
        return '';
    }
    let ret = modal.$t(messageKey);
    if (ret.indexOf('####') !== -1) {
      // 所定文字列に対して replace を使って動的にメッセージ生成をするもの
      ret = ret.replace('####', details);
    } else {
      // Javascript 標準の String format を使って動的にメッセージ生成をするもの
      const detailsFormt = [];
      if (Array.isArray(details)) {
        for (let i = 0; i < details.length; i++) {
          detailsFormt.push(details[i].join(', '));
        }
      } else {
        detailsFormt.push(details);
      }
      ret = modal.$t(messageKey, detailsFormt);
    }
    return messageKey === ret ? '' : ret;
  }
  return modal.$t(api.getErrorMessageForCode(code));
}

function getNetworkErrorMessage(modal) {
  let message = '';
  let type = null;
  let causeType = null;

  if (frontType === 'manage') {
    // execute と manage でエラー後の動作を変える manage はエラー表示をさせる 
    type = behaviorTypes.other;
    message = getMessage(error.NO_NETWORK_ERROR, modal, 'code');
  }
  else {
    // execute と manage でエラー後の動作を変える execute はログアウト(resetSession)させる
    type = behaviorTypes.resetSession;
    message = getMessage('message.common_browser_no_network_message', modal, 'id');
  }
  causeType = causeTypes.networkError;

  const resultInfo = {
    message,
    type,
    causeType
  };
  return resultInfo;
}

function checkErrorMessage(modal, message, other) {
  let result = message;
  if (!result) {
    if (modal) {
      result = modal.$t('message.common_systemError') + " (" + other + ")";
    } else {
      // modal が設定されていない場合は固定メッセージを返却する
      result = "An error occurred in this cloud app. (status: No Model)";
    }
  }
  return result;
}

export const validateTypes = {
  all: 1,
  permission: 2,
  response: 3,
  error: 4
};

export const behaviorTypes = {
  logout: 1,
  resetSession: 2,
  other: 3
};

export const causeTypes = {
  unexpectedError: 0,
  alreadyDeleted: 1,
  permissionError: 2,
  invalidSessionToken: 3,
  networkError: 4,
  clientConnectorNotAliveError: 5,
  beforMaintenanceError: 6,
  maintenanceError: 7,
  other: 8,
  differentVinError: 9
};

export default {
  init,
  validate,
  validateTypes,
  behaviorTypes,
  causeTypes,
  //setFakeMaintenanceMode
};