<div class="container-fluid ml-n2">
  <div content-class="mt-3">
    <div :class="nextPaneBg">
      <div v-if="!isReproPossible()" class="col-12 text-lg-center h6 pt-5">
        <strong v-html="$t(nextMessage)"></strong>
      </div>
      <div v-if="isReproPossible()" class="row col-12 text-left pt-2 detail-component-style">
        <label>{{ $t('message.header_vehicle_table_system_pfc_file_name') }} {{ pfcFileName }}</label>
      </div>
      <cstm-table v-if="isReproPossible()" :items="targetUnitDetails" hasTabs :columns="systemTableColumns" class="mt-2"
        type="simple" currentSortkey="unit" usePopup>
      </cstm-table>
      <div v-if="isReproPossible()" class="row col-12 text-left detail-component-style"
        style="justify-content: space-between;">
        <div class="row">
          <div v-if="merchantabilityOrRestore" class="col-12">
            <label> {{ $t('message.label_vehicle_system_program_type') }} {{ $t(reproType) }}</label>
          </div>
          <div v-else class="col-12">
            <label>{{ $t('message.label_system_repro_type') }} {{ $t(reproType) }}</label>
          </div>
          <div class="col-12">
            <label class="mt-1">{{ $t('message.label_vehicle_system_translations') }}</label>
            <b-form-select v-model="selectedLanguage" id="translation_language" size="sm" @change="updateLanguage"
              class="w-25 ml-1 custom-select custom-select-sm" :disabled="isTestMode">
              <option v-for="lng in translationLangs" :value="lng.value">{{ lng.text }}</option>
            </b-form-select>
          </div>
        </div>
        <!-- ソフトウェア更新許諾ボタン -->
        <div v-if="isShowSwidConsentBtn && vehicleInfo.vehicle.is_su_flow" class="text-center">
          <b-button variant="primary" class="btn-style" size="sm" @click="openConsentModal()">
            {{ $t('message.button_vehicle_system_sw_update') }}
          </b-button>
        </div>
      </div>

      <div v-if="isReproPossible()" class="col-12 mt-3">
        <div class="row">
          <div v-if="merchantabilityOrRestore" class="detail-component-style">
            <label> {{ $t('message.label_vehicle_system_program_title') }}</label>
          </div>
          <div v-else class="detail-component-style">
            <label>{{ $t('message.label_vehicle_system_service_news') }}</label>
          </div>
          <div v-if="serviceNewsUrl" class="detail-component-style line-border" style="min-height: 62px;">
            <span v-for="(news, index) in serviceNewsList" class="text-break" style="display: inline;">
              <a class="text-break" :href="news.url" target="_blank" rel="noopener noreferrer">
                {{ truncate(news.name) }}
              </a>
              <span v-if="index != serviceNewsList.length-1" class="text-break" style="display:inline;">{{
                getServiceNewsSeparator() }}</span>
            </span>
          </div>
          <div v-else class="col-12">
            <b-form-textarea class="textarea-bg-white" :disabled=true v-model="CombinedServiceNewsText">
            </b-form-textarea>
          </div>
          <div v-if="domainInfo.type === 'third_party'" class="mt-2 col-12">
            <label>{{ $t('message.message_vehicle_under_system_service_news') }}</label>
          </div>
        </div>
      </div>
      <div v-if="isReproPossible()" class="col-12 text-left mt-3">
        <div v-if="merchantabilityOrRestore">
          <label> {{ $t('message.label_vehicle_system_program_contents') }}</label>
        </div>
        <div v-else>
          <label>{{ $t('message.label_vehicle_system_details') }}</label>
        </div>
        <b-form-textarea class="textarea-bg-white" :disabled=true rows="8" v-model="details"></b-form-textarea>
      </div>
      <div v-if="isReproPossible()" class="col-12 text-left mt-4">
        <div v-if="merchantabilityOrRestore">
          <label> {{ $t('message.label_vehicle_system_precautions') }}</label>
        </div>
        <div v-else>
          <label>{{ $t('message.label_vehicle_system_notes') }}</label>
        </div>
        <b-form-textarea class="textarea-bg-white" :disabled=true v-model="notes"></b-form-textarea>
      </div>
      <div v-if="isReproPossible() && nextMessage" class="text-center mt-5">
        <strong v-html="$t(nextMessage)" class="alert-msg-color"></strong>
      </div>
      <div v-if="isReproPossible() && paneConfig.isExecutable && executeStartBtn"
        class="text-center mt-3 checkbox-size-lg">
        <div v-if="region === 'Domestic'">
          <p class="mb-2">{{ $t('message.message_system_repro_checkbox_domestic') }}</p>
          <b-form-checkbox v-model="completionCheckServiceNews">
            {{ $t('message.label_system_repro_checkbox_domestic') }}
          </b-form-checkbox>
        </div>
        <div v-else>
          <p class="mb-2">{{ $t('message.message_system_repro_checkbox_other') }}</p>
          <b-form-checkbox v-model="completionCheckServiceNews">
            {{ $t('message.label_system_repro_checkbox_other') }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-12 text-center mt-5 mb-3">
        <b-button variant="outline-primary" style="background-color: white;" v-on:click="back()" class="mr-3 mb-5">
          {{ $t('message.common_button_back') }}</b-button>
        <b-button v-if="isDownloadable() && isReproPossible() && paneConfig.isExecutable && executeStartBtn" variant="primary"
          v-on:click="downloadZip()" class="ml-3 mr-3 mb-5">{{ $t('message.button_vehicle_system_download_zip') }}
        </b-button>
        <b-button v-if="isReproPossible() && paneConfig.isExecutable && executeStartBtn"
          variant="primary" v-on:click="startRepro()" class="ml-3 mb-5" :disabled="!completionCheckServiceNews">
          <div v-if="merchantabilityOrRestore">
            {{ $t('message.button_vehicle_system_start') }}
          </div>
          <div v-else>
            {{ $t('message.button_vehicle_system_execute_repro') }}
          </div>
        </b-button>
      </div>
    </div>
  </div>

  <!-- USB照合開始ダイアログ -->
  <b-modal v-model="showModalUsb" id="modal-confirm-usb" header-bg-variant="secondary" size="lg"
    header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc @ok="startVerificationUsb()">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_system_confirm_usb') }}
      </div>
    </template>
    <template v-slot:default>
      <div class="col-12 mt-3 text-center">
        {{ $t('message.message_vehicle_system_confirm_usb') }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" style="background-color: white;" @click="showModalUsb=false"
          class="ml-2 mr-2" :disabled="processing">
          {{ $t('message.common_button_cancel') }}</b-button>
        <b-button size="sm" variant="primary" @click="startVerificationUsb()" class="ml-2 mr-2" :disabled="processing">
          {{ $t('message.common_button_ok') }}</b-button>
      </div>
    </template>
  </b-modal>

  <!-- zipダウンロード用 -->
  <iframe style="display:none;" :src="fileUrl"></iframe>

  <processing-view :processing="processing"></processing-view>
</div>