export default {
  namespaced: true,

  state: {
    // 選択ユニット
    unitInfo: null,
    // 選択ユニットの詳細情報 (対象)
    unitDetailInfo: null,
    // 対象ユニットが待機処理が長いシステムかの判定フラグ
    isLongInterval:false,
  },

  mutations: {
    // 選択ユニット情報
    setTargetUnit(state, payload) {
      state.unitInfo = payload;
    },

    // 選択ユニットの詳細情報を設定 
    setUnitDetailInfo(state, payload) {
      state.unitDetailInfo = payload;
    },
    // 待機処理が長いシステムか判定
    setIsLongInterval(state, payload){
      state.isLongInterval=payload;
    }
  },

  actions: {
    // * @param unitId: unitのIDを指定
    // * @param name: ユニット名
    // * @param fileId: PFCのファイルID
    // * @param publishmentMode: 公開・非公開   
    setTargetUnit(context, payload) {
      context.commit('setTargetUnit', payload);
    },

    setUnitDetailInfo(context, payload) {
      context.commit('setUnitDetailInfo', payload);
    },
    setIsLongInterval(context, payload) {
      context.commit('setIsLongInterval', payload);
    },
  }
};